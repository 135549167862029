import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { FILTERS_CONTAINS } from "@kraftheinz/common";
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "start-of-month__container"
  }, [_vm.data ? _c("a-card", {
    staticClass: "neutral-10--text",
    attrs: {
      "loading": _vm.isLoading
    }
  }, [_c("a-row", [_c("a-col", {
    staticClass: "h6",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Start of Month")]), _c("a-col", {
    staticClass: "h6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v("Containers")]), _c("a-col", {
    staticClass: "h6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v("USD")])], 1), _c("a-row", [_c("a-col", {
    staticClass: "b6",
    attrs: {
      "span": 12
    }
  }, [_vm._v(_vm._s(_vm.shortNameLastMonth) + " Orders")]), _c("a-col", {
    staticClass: "b6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v(_vm._s(_vm.data.order.containers))]), _c("a-col", {
    staticClass: "b6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.data.order.valueUSD)))])], 1), _c("a-row", [_c("a-col", {
    staticClass: "b6",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Backlog (previous to " + _vm._s(_vm.shortNameLastMonth) + ")")]), _c("a-col", {
    staticClass: "b6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v(_vm._s(_vm.data.backlog.containers))]), _c("a-col", {
    staticClass: "b6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.data.backlog.valueUSD)))])], 1), _c("a-row", [_c("a-col", {
    staticClass: "b6",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Carryover (beyond " + _vm._s(_vm.shortNameLastMonth) + ")")]), _c("a-col", {
    staticClass: "b6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v(_vm._s(_vm.data.carryover.containers))]), _c("a-col", {
    staticClass: "b6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.data.carryover.valueUSD)))])], 1), _c("a-row", [_c("a-col", {
    staticClass: "h6",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Total")]), _c("a-col", {
    staticClass: "h6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v(_vm._s(_vm.data.total.containers))]), _c("a-col", {
    staticClass: "h6 text-right",
    attrs: {
      "span": 6
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.data.total.valueUSD)))])], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns$5 = [];
var StartOfMonthData_vue_vue_type_style_index_0_scoped_true_lang = "";
const usdFormatter$1 = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});
const __vue2_script$5 = {
  name: "StartOfMonthData",
  inject: ["apiUrl", "crud", "can", "resourceName"],
  props: {
    filter: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      data: null,
      isLoading: false,
      ENDPOINT_URL: "/export-order/summary-tracking-statuses/start-of-month",
      shortNameLastMonth: ""
    };
  },
  computed: {},
  watch: {
    filter(newValue) {
      let url = "";
      for (const [key, value] of Object.entries(newValue)) {
        if (value) {
          key === "TypeData" ? url += "client=" + value : url += "countryCode=" + value + "&";
        }
      }
      url = this.ENDPOINT_URL + "?" + url;
      this.getData(url);
    }
  },
  created() {
    this.crud.deleteFilter("IsInactive");
    this.getData(this.ENDPOINT_URL);
    this.getCurrentPeriod();
  },
  methods: {
    formatCurrency(value) {
      return usdFormatter$1.format(value);
    },
    getData(endPointURL) {
      this.isLoading = true;
      this.axios.get(`${this.apiUrl}` + endPointURL).then((res) => {
        let { data: dataSOM } = res;
        this.data = dataSOM;
      }).finally(() => this.isLoading = false);
    },
    getCurrentPeriod() {
      this.axios.get(`${this.apiUrl}/export-order/common/current-period`).then((res) => {
        let { data: values } = res;
        this.shortNameLastMonth = this.$moment.monthsShort(values.currentPeriod - 1);
      });
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "75f63060", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var StartOfMonthData$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "summary"
  }, [_c("resource", {
    attrs: {
      "name": "export-order.summary-tracking-statuses.start-of-month",
      "api-url": _vm.apiUrl
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("start-of-month-data", {
    attrs: {
      "filter": _vm.filter
    }
  })], 1)], 1)], 1), _c("router-view")], 1);
};
var staticRenderFns$4 = [];
const apiUrl$3 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  components: {
    StartOfMonthData: StartOfMonthData$1
  },
  props: {
    filter: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      apiUrl: apiUrl$3
    };
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var StartOfMonthData = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", {
    staticClass: "summary-table__container"
  }, [_c("div", {
    staticClass: "ant-col ant-col-24"
  }, [_c("h5", {
    staticClass: "h5 mb-3 neutral-10--text"
  }, [_vm._v(_vm._s(_vm.title))])]), _c("a-table", {
    attrs: {
      "loading": _vm.isLoading,
      "columns": _vm.columns,
      "data-source": _vm.resourceList,
      "bordered": "",
      "pagination": false,
      "row-key": "id"
    }
  })], 1);
};
var staticRenderFns$3 = [];
var SummaryTable_vue_vue_type_style_index_0_scoped_true_lang = "";
const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});
const __vue2_script$3 = {
  name: "NewZelandHeinz",
  inject: ["apiUrl", "crud", "can", "resourceName"],
  props: {
    filter: {
      type: Object,
      default: null
    },
    title: {
      type: String || Array,
      default: ""
    }
  },
  data() {
    return {
      columns: []
    };
  },
  computed: {
    resourceList() {
      let dataSource = [];
      let ressponse = [...this.crud.getList()];
      if (ressponse.length > 0) {
        this.remapColumn(ressponse[0]);
        dataSource = this.remapDataSource(ressponse);
      }
      return dataSource;
    },
    isLoading() {
      return this.$store.state[this.resourceName].isFetchingList;
    }
  },
  watch: {
    filter(newValue) {
      for (const [key, value] of Object.entries(newValue)) {
        this.crud.setFilter(key, {
          operator: FILTERS_CONTAINS,
          value
        });
      }
      this.crud.fetchList();
    }
  },
  created() {
    this.crud.fetchList();
  },
  methods: {
    formatCurrency(value) {
      return usdFormatter.format(value);
    },
    remapColumn(values) {
      this.columns = [
        {
          title: "",
          dataIndex: "title",
          key: "title"
        },
        {
          title: "Current Month",
          children: [
            {
              title: "Cntrs",
              dataIndex: "cntrsSOM",
              key: "cntrsSOM"
            },
            {
              title: "USD",
              dataIndex: "usdSOM",
              key: "usdSOM"
            }
          ]
        }
      ];
      if (values.data) {
        values.data.forEach((element) => {
          this.columns.push({
            title: "Week " + element.week,
            children: [
              {
                title: "Cntrs",
                dataIndex: "cntrs" + element.week,
                key: "cntrs" + element.week
              },
              {
                title: "USD",
                dataIndex: "usd" + element.week,
                key: "usd" + element.week
              }
            ]
          });
        });
      }
    },
    remapDataSource(values) {
      const lists = [];
      values.forEach((ele, index2) => {
        const obj1 = {
          id: index2,
          title: ele.title,
          cntrsSOM: ele.startOfMonth.cntrs,
          usdSOM: this.formatCurrency(ele.startOfMonth.usd)
        };
        const obj2 = {};
        ele.data.forEach((val) => {
          obj2["cntrs" + val.week] = val.cntrs;
          obj2["usd" + val.week] = this.formatCurrency(val.usd);
        });
        lists.push({ ...obj1, ...obj2 });
      });
      return lists;
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "84224b7e", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var SummaryTable$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "summary-table"
  }, [_c("resource", {
    attrs: {
      "name": _vm.url,
      "api-url": _vm.apiUrl
    }
  }, [_c("summary-table", {
    attrs: {
      "title": _vm.title,
      "filter": _vm.filter
    }
  })], 1), _c("router-view")], 1);
};
var staticRenderFns$2 = [];
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    SummaryTable: SummaryTable$1
  },
  props: {
    url: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      default: null
    },
    title: {
      type: String || Array,
      default: ""
    }
  },
  data() {
    return {
      apiUrl: apiUrl$2
    };
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var SummaryTable = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list"
  }, [_c("a-row", {
    staticClass: "list__title pt-2 pb-4"
  }, [_c("a-col", {
    staticClass: "background--white",
    attrs: {
      "span": 24
    }
  }, [_c("h2", {
    staticClass: "mb-0 neutral-10--text"
  }, [_vm._v("Summary by Tracking Status")])])], 1), _c("div", {
    staticClass: "list__table p-5"
  }, [_c("div", {
    staticClass: "list__basic-filters d-flex justify-content-between mb-3"
  }, [_c("div", {
    staticClass: "d-flex justtify-content-between align-items-end"
  }, [_c("div", {
    staticClass: "filter mr-4"
  }, [_c("select-input", {
    key: "Country",
    attrs: {
      "data-source": _vm.countries,
      "source": "countryCode",
      "source-label": "name",
      "default-active-first-option": true
    },
    on: {
      "change": function($event) {
        return _vm.onFilterChange("Country", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "filter mr-4"
  }, [_c("select-input", {
    key: "Client",
    attrs: {
      "data-source": _vm.clients,
      "source": "code",
      "source-label": "name",
      "default-active-first-option": true
    },
    on: {
      "change": function($event) {
        return _vm.onFilterChange("Client", $event);
      }
    }
  })], 1)])]), _c("div", {
    staticClass: "mb-4"
  }, [_c("start-of-month-data", {
    attrs: {
      "filter": _vm.filterValue
    }
  })], 1), _c("div", {
    staticClass: "mb-4"
  }, [_c("summary-table", {
    attrs: {
      "title": _vm.titleCountry + " - " + _vm.titleClient,
      "url": "export-order.summary-tracking-statuses.summary-by-tracking-statuses",
      "filter": _vm.filterValue
    }
  })], 1), _c("div", {
    staticClass: "mb-4"
  }, [_c("summary-table", {
    attrs: {
      "title": "Risk",
      "url": "export-order.summary-tracking-statuses.summary-by-risk",
      "filter": _vm.filterValue
    }
  })], 1), _c("div", {
    staticClass: "mb-4"
  }, [_c("summary-table", {
    attrs: {
      "title": "Carryover",
      "url": "export-order.summary-tracking-statuses.summary-by-carryover",
      "filter": _vm.filterValue
    }
  })], 1)])], 1);
};
var staticRenderFns$1 = [];
var Main_vue_vue_type_style_index_0_scoped_true_lang = "";
const filter = { CountryCode: "", TypeData: "" };
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ListTrackingStatus",
  components: {
    StartOfMonthData,
    SummaryTable
  },
  data() {
    return { filter, countries: [], clients: [], apiUrl: apiUrl$1 };
  },
  computed: {
    filterValue() {
      return this.filter;
    },
    titleCountry() {
      switch (this.filter.CountryCode.toLowerCase()) {
        case "au":
          return "Australia";
        case "nz":
          return "New Zealand";
        default:
          return "All Countries";
      }
    },
    titleClient() {
      switch (this.filter.TypeData.toLowerCase()) {
        case "heinz":
          return "Heinz";
        case "cerebos":
          return "Cerebos";
        default:
          return "All Clients";
      }
    }
  },
  created() {
    this.getCountries();
    this.getClients();
  },
  methods: {
    onFilterChange(type, data) {
      if (type === "Country" && this.filter.CountryCode !== data) {
        this.filter = { ...this.filter, CountryCode: data };
      }
      if (type === "Client" && this.filter.TypeData !== data) {
        this.filter = { ...this.filter, TypeData: data };
      }
    },
    async getCountries() {
      const { data } = await this.axios.get(`${this.apiUrl}/export-order/common/countries`);
      this.countries = [
        {
          id: 0,
          name: "All",
          countryCode: ""
        },
        ...data
      ];
    },
    async getClients() {
      const { data } = await this.axios.get(`${this.apiUrl}/export-order/common/clients`);
      this.clients = [
        {
          id: 0,
          name: "All",
          code: ""
        },
        ...data
      ];
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "49d3c20c", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Main = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-tracking-status"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("Main"), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    Main
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "summary-tracking-status",
          title: "Summary by Tracking Status",
          path: "/export-orders/summary-tracking-status"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
